import { Injectable } from '@angular/core';
 import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WarehouseActions } from '.';
import {WarehouseService} from './warehouse.service';
import { catchError, switchMap, from, map, of, mergeMap } from 'rxjs';
import { MessageService } from 'primeng/api';

@Injectable()
export class WarehouseEffects {
  constructor(
    private actions$: Actions,
    private warehouseService: WarehouseService,
    private messageService: MessageService,

  ) {}

  loadSupplyList$ = createEffect(() =>
    this.actions$.pipe(
        ofType(WarehouseActions.loadSupplyList),
        switchMap((action) => {
            return from(this.warehouseService.effectGetSupplyList(action.payload)).pipe(
                switchMap((res) => {
                    return [WarehouseActions.loadSupplyListWithPaginationSuccess({ response: res }), WarehouseActions.changeShowLoaderValue({ show : false })]
                }),
                catchError((error) => {
                  return [(WarehouseActions.warehouseFailureMethod({ err: error })),(WarehouseActions.changeShowLoaderValue({ show : false }))]
                })
            )
        })
    )
  )

  loadSelectedSupplyListByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WarehouseActions.loadSelectedSupplyListByIds),
      switchMap((action) => {
        return from(this.warehouseService.effectGetSupplyList(action.payload)).pipe(
          switchMap((res) => {
            return [WarehouseActions.loadSelectedSupplyListByIdsSuccess({ response: res })]
          }),
          catchError((error) => {
            return [(WarehouseActions.warehouseFailureMethod({ err: error }))]
          })
        )
      })
    )
  )

  loadSupplyListForOrder$ = createEffect(() =>
    this.actions$.pipe(
        ofType(WarehouseActions.loadSupplyListForOrder),
        switchMap((action) => {
            return from(this.warehouseService.effectGetSupplyList(action.payload)).pipe(
                switchMap((res) => {
                    return [
                      WarehouseActions.loadSupplyListForOrderWithPaginationSuccess({ response: res }), 
                      WarehouseActions.changeShowLoaderValue({ show : false })
                    ]
                }),
                catchError((error) => {
                  return [(WarehouseActions.warehouseFailureMethod({ err: error })),(WarehouseActions.changeShowLoaderValue({ show : false }))]
                })
            )
        })
    )
  )

  loadSupplyChangesForOrder$ = createEffect(() =>
    this.actions$.pipe(
        ofType(WarehouseActions.loadSupplyChangesForOrder),
        switchMap((action) => {
            return from(this.warehouseService.effectGetSupplyChanges(action.payload)).pipe(
                switchMap((res) => {
                    return [
                      WarehouseActions.loadSupplyLogsForOrderWithPaginationSuccess({ response: res }), 
                      WarehouseActions.changeShowLoaderValue({ show : false })
                    ]
                }),
                catchError((error) => {
                  return [(WarehouseActions.warehouseFailureMethod({ err: error })),(WarehouseActions.changeShowLoaderValue({ show : false }))]
                })
            )
        })
    )
  )

  createMultipleBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WarehouseActions.createMultipleBatch),
    mergeMap((action) => {
       return from(this.warehouseService.createMultipleBatch(action.locationId, action.payload)).pipe(
          map((res:any) => WarehouseActions.createMultipleBatchSuccess({res})),
          catchError((error) => of(WarehouseActions.warehouseFailureMethod({err: error})))
        )
     })
    )
  );

  createMultipleBatchForMultLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WarehouseActions.createMultipleBatchForMultLocations),
    mergeMap((action) => {
       return from(this.warehouseService.createMultipleBatchesForMultipleLocations(action.batchesformultiplelocations)).pipe(
          map((res:any) => WarehouseActions.createMultipleBatchForMultLocationsSuccess({res})),
          catchError((error) => of(WarehouseActions.warehouseFailureMethod({err: error})))
        )
     })
    )
  );

  updateSoldBatchesQuantity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WarehouseActions.updateSoldBatchesQuantity),
      mergeMap((action) => {
        return from(this.warehouseService.updateSoldBatchesQuantity(action.batches)).pipe(
          switchMap((res) => {
            const actions: any[] = [WarehouseActions.updateSoldBatchesQuantitySuccess({ res })]

            const updatedBatchesIds = (res['allSold'] || [])?.map((({ _id }) => (_id))).join(',')
            if (updatedBatchesIds) {
              actions.push(WarehouseActions.loadSelectedSupplyListByIds({ payload: { ids: updatedBatchesIds } }))
            }
            return actions
          }),
          catchError((error) => {
            []
            this.messageService.add({ key: 'global-notification', severity: 'error', summary: 'Error', detail: 'Error Occured.' });
            return [(WarehouseActions.updateSoldBatchesQuantityFailure({ error }))]
          })
        )
      })
    )
  );

  updateAisleBayToBatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WarehouseActions.updateAisleBayToBatches),
      mergeMap((action) => {
        return from(this.warehouseService.updateAisleBayToBatches(action.payload)).pipe(
          switchMap((res) => {
            const actions: any[] = []
            this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'Success', detail: res?.['msg'] });
            const updatedBatchesIds = (action.payload || [])?.map((({ _id }) => (_id))).join(',')
            if (updatedBatchesIds) {
              actions.push(WarehouseActions.loadSelectedSupplyListByIds({ payload: { ids: updatedBatchesIds } }))
            }
            return actions
          }),
          catchError((error) => {
            []
            this.messageService.add({ key: 'global-notification', severity: 'error', summary: 'Error', detail: error?.errorMsg });
            return []
          })
        )
      })
    )
  );

  updateBathcesWithProductData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WarehouseActions.updateBathcesWithProductData),
      mergeMap((action) => {
        return from(this.warehouseService.getProductsDataByBatch(
          action.batchId, action.skip, action.limit,action.bayId, action.aisleId, action.assetId,action.stockLocationId
        )).pipe(
          map((productsByBathces: any) => WarehouseActions.updateBathcesWithProductDataSuccess({ productsByBathces, batchId: action.batchId })),
          catchError((error) => of(WarehouseActions.warehouseFailureMethod({ err: error })))
        )
      })
    )
  );

  updateInventoryProductList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WarehouseActions.updateInventoryProductList),
      mergeMap((action) => {
        return from(this.warehouseService.updateInventoryProductList(
          action.locationId, action.products
        )).pipe(
          switchMap((res) => {
            this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'Success', detail: res?.['message'] });
            return []
        }),
        catchError((error) => {
          console.log(error,'error')
          this.messageService.add({ key: 'global-notification', severity: 'error', summary: 'error', detail: error?.error?.msg ?? 'Error Occured...........' });
          return [(WarehouseActions.warehouseFailureMethod({ err: error }))]
        })
        )
      })
    )
  );
}
import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";

export const selectWarehouse = (state:AppState) => state.warehouse

export const getWarehouseSupplyRawDetails = createSelector(
    selectWarehouse,
      (warehouse) => warehouse.supplyRawData
  )

  export const getWarehouseSupplyRawDetailsOfOrder = createSelector(
    selectWarehouse,
      (warehouse) => warehouse.supplyRawDataOfOrder
  )
  
  export const getAnyError = createSelector(
    selectWarehouse, 
    (warehouse)=>warehouse.error
  )
  
  export const showLoader = createSelector(
    selectWarehouse, 
    (warehouse)=>warehouse.showLoader
  )

  export const getNewlyAddedBatches = createSelector(
    selectWarehouse, 
    (warehouse)=>warehouse.newlyAddedBatches
  )

export const getBatchesSoldQuantityInfo = createSelector(
  selectWarehouse,
  (warehouse) => warehouse.batchesSoldQuantityInfo
)

export const getProductsWithSerialNumber = createSelector(
  selectWarehouse,
  (warehouse) => warehouse.productsWithSerialNumber
)

export const getWarehouseSupplyLogs = createSelector(
  selectWarehouse,
    (warehouse) => warehouse.supplyLogs
)